import React from "react";
import App from "../containers/App";
import { Route, Switch, Redirect } from "react-router-dom";

const Container = React.lazy(() => import("../containers/Container/Container"));
const AuthContainer = React.lazy(() => import("../containers/AuthContainer/AuthContainer"));
const GetStartContainer = React.lazy(() =>
  import("../containers/GetStartContainer/GetStartContainer")
);
const ContinueWithPaypal = React.lazy(() =>
  import("../components/ContinueWithPaypal/ContinueWithPaypal")
);
const AppDownloadAndroid = React.lazy(() => import("../components/PublicPages/AppDownloadAndroid"));
const TermsAndConditions = React.lazy(() =>
  import("../components/PublicPages/TermsAndConditions/TermsAndConditions")
);
const PrivacyPolicy = React.lazy(() =>
  import("../components/PublicPages/PrivacyPolicy/PrivacyPolicy")
);
const Impressum = React.lazy(() => import("../components/PublicPages/Impressum/Impressum"));
const Cookies = React.lazy(() => import("../components/PublicPages/Cookies/Cookies"));
const AppsDownload = React.lazy(() =>
  import("../components/PublicPages/AppsDownload/AppsDownload")
);
const NotFound = React.lazy(() => import("../components/NotFound/NotFound"));
const Fifa20Rules = React.lazy(() => import("../components/Fifa20Rules/Fifa20Rules"));
const DeleteAccountPage = React.lazy(() =>
  import("../components/PublicPages/DeleteAccountPage/DeleteAccountPage")
);
const AppUpdateAndroid = React.lazy(() => import("../components/PublicPages/AppUpdateAndroid"));
const AffiliateLandingPage = React.lazy(() =>
  import("../components/PublicPages/AffiliateLandingPage/AffiliateLandingPage")
);
const TournamentsRules = React.lazy(() =>
  import("../components/PublicPages/TournamentsRules/TournamentsRules")
);
// const ICOWebsite = React.lazy(() => import("../containers/ICOWebsite"));
const B2BWebsite = React.lazy(() => import("../containers/B2BWebsite"));
const Homepage = React.lazy(() => import("../containers/Homepage"));

export default (
  <App>
    <Switch>
      <Route
        path="/"
        exact
        render={() => (!!localStorage.token ? <Redirect to="/admin" /> : <Homepage />)}
      />

      <Route path="/auth" component={AuthContainer} />
      <Route path="/get-started" component={GetStartContainer} />
      <Route path="/admin" component={Container} />

      <Route
        path="/referral/:code"
        exact
        render={(props) =>
          !!localStorage.token ? (
            <Redirect to={`/admin/referral?invitation=${props.match.params.code}`} />
          ) : (
            <Redirect to={`/auth/register?referral=${props.match.params.code}`} />
          )
        }
      />

      <Route path="/continue-with-paypal/:info" component={ContinueWithPaypal} />
      <Route path="/app-download-android" component={AppDownloadAndroid} />
      <Route path="/app-update-android" component={AppUpdateAndroid} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route path="/AGBs/" component={TermsAndConditions} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/datenschutz/" component={PrivacyPolicy} />
      <Route path="/impressum/" component={Impressum} />
      <Route path="/cookies" component={Cookies} />
      <Route path="/apps-download" component={AppsDownload} />
      <Route path="/rules/fifa20" component={Fifa20Rules} />
      <Route path="/delete-account" component={DeleteAccountPage} />
      <Route path="/affiliates" component={AffiliateLandingPage} />
      <Route path="/tournament-rules/:id" component={TournamentsRules} />
      {/*<Route path="/initial-dex-offering" component={ICOWebsite} />*/}
      <Route path="/b2b" component={B2BWebsite} />

      <Route component={NotFound} />
    </Switch>
  </App>
);
